import React, {useEffect, useState} from 'react';
import {vendorService} from '../../vendor/services/VendorService';
import SpinnerInline from '../../../components/Spinner/SpinnerInline';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import {toDateString} from '../../../services/DateUtils';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {prefixWithTenantDomain} from '../../../services/TenantService';
import {PurchaseStatusIndicator} from './PurchaseStatusIndicator';
import {PriceCurrencyLiteral} from '../../../services/MoneyUtils';
import UseAssetImage from '../../../hooks/UseAssetImage';
import DepartmentToBeCharged from './DepartmentToBeCharged';
import {VendorLink} from './VendorLink'
import Checkbox from '@material-ui/core/Checkbox';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {Collapse, TextareaAutosize} from '@material-ui/core';
import {notificationService} from '../../../components/Notifications/Notifications';
import {assetService} from '../services/AssetService';
import {PaymentStatus} from '../models/Purchase';
import {MAX_INPUT_LENGTH, MAX_OBJECT_DESCRIPTION_LENGTH} from '../../../constants';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: 600,
  },
  title: {
    flex: '1 1 100%',
  },
  table: {
    minWidth: 650,
  },
  usages: {
    minWidth: 300,
  }
}));

const checkBoxStyles = () => ({
  root: {
    '&$checked': {
      color: '#FF0954',
    },
  },
  checked: {},
});

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

const PurchaseRow = React.memo(({
                                  purchase,
                                  index,
                                  handleAssetDetails,
                                  handleSelectPurchase,
                                  isSelected,
                                  shouldShowCheckbox,
                                  shouldDisableCheckbox
                                }) => {

  const classes = useStyles();
  const [loadingVendor, setLoadingVendor] = useState(true);
  const [vendor, setVendor] = useState({...purchase.vendor})
  const [isEditMode, setIsEditMode] = useState(false)
  const [objectDescription, setObjectDescription] = useState(purchase.metadata.objectDescription)
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    if (purchase.paymentStatus !== PaymentStatus.ISSUED) {
      setIsEditMode(true)
    }
  }

  const handleClickAway = () => {
    if (objectDescription && objectDescription !== purchase.metadata.objectDescription) {
      if (objectDescription.length < MAX_INPUT_LENGTH) {
        handleEditObjectDescription(purchase, objectDescription).then(() => {
          purchase.metadata.objectDescription = objectDescription;
          notificationService.success(`Die Beschriftung des folgenden Artikels wurde aktualisiert ${purchase.assetId.damAssetId}`);
        }).catch(() => {
          notificationService.error(`Folgender Artikel konnte nicht aktualisiert werden: ${purchase.assetId.damAssetId}`);
        });
      } else {
        notificationService.error(`Die Überschrift des folgenden Artikels konnte aufgrund der Größenbeschränkung von 255 Zeichen nicht aktualisiert werden: ${purchase.assetId.damAssetId}`);
      }
    }
    setIsEditMode(false);
  };

  const handleEditObjectDescription = async (purchase, objectDescription) => {
    const payload = {
      objectDescription
    }
    await assetService.updateObjectDescription(purchase.assetId.damAssetId, payload)
  };

  useEffect(async () => {
    if (vendor && vendor.vendorKey && !vendor.vendorName) {
      await vendorService.getVendorByIdCached(vendor.vendorKey)
        .then((response) => setVendor((vendor) => ({...vendor, vendorName: response?.personalData?.companyName})))
        .catch(() => {/* nothing to be done */
        })
        .finally(() => setLoadingVendor(false));
    } else {
      setLoadingVendor(false);
    }
  }, [vendor.vendorKey]);

  const usages = purchase.usages || [];
  const initialUsages = usages.slice(0, 10);
  const additionalUsages = usages.slice(10);

  const restrictionNoteSet = purchase && purchase.restrictionNoteSet;

  function descSnipper(description) {
    if (description) {
      return description.length > MAX_OBJECT_DESCRIPTION_LENGTH ? description.substring(0, MAX_OBJECT_DESCRIPTION_LENGTH) + '...' : description
    } else {
      return '-'
    }
  }

  return (
    <TableRow
      key={index}
      hover={true}
      data-testid="purchaseTable--item"
      className={purchase.hasExcessivePrice ? 'purchaseTable__assetExcessivePrice' : ''}
    >
      <TableCell>
        <div className="purchaseTable__statusAndCheckbox">
          {shouldShowCheckbox && <CustomCheckbox
            className="creditadvicedetail__checkbox"
            data-testid="purchase_list_all__checkbox"
            disabled={shouldDisableCheckbox}
            checked={isSelected(purchase.assetId.damAssetId)}
            onClick={() => handleSelectPurchase(purchase.assetId.damAssetId)}

          />}
          <div className="purchaseTable__statusIcons">
            <PurchaseStatusIndicator purchase={purchase}/>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className="purchaseTable__assetImg" data-testid="purchaseTable--thumbnailButton"
             onClick={() => handleAssetDetails(purchase, vendor)}>
          <UseAssetImage
            thumbnailUrl={purchase?.metadata?.thumbnailUrl ?? ''}
            damAssetUrl={purchase?.metadata?.damAssetUrl ?? ''}
            assetId={purchase?.assetId?.damAssetId ?? ''}
            testIdAvailable={'purchaseTable--thumbnail'}
            restrictionNoteSet={restrictionNoteSet}
            isPurchaseList={true}
            isCollage={purchase.isCollage}
          />
        </div>
      </TableCell>
      <TableCell>
        <div data-testid="purchaseTable--assetDamId">{purchase.assetId.damAssetId || '-'}</div>
        {purchase.metadata.shortId &&
          <div>
            <div style={{'userSelect': 'none'}}>-----</div>
            <div>{purchase.metadata.shortId}</div>
          </div>
        }
      </TableCell>
      <TableCell>
        <Tooltip title={purchase.metadata.credit || ''} enterDelay={300} leaveDelay={200} placement="top">
          <div className="purchaseTable__credit"
               data-testid="purchaseTable--credit">{purchase.metadata.credit || '-'}</div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={purchase.metadata.foreignId || ''} enterDelay={300} leaveDelay={300} placement="top">
          <div
            className="purchaseTable__assetForeignId"
            data-testid="purchaseTable--assetForeignId"
          >
            {purchase.metadata.foreignId || '-'}
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <DepartmentToBeCharged name={purchase.departmentToBeCharged.name}/>
      </TableCell>
      <TableCell>
        {loadingVendor ? <SpinnerInline/> : <VendorLink vendorKey={vendor.vendorKey} vendorName={vendor.vendorName}/>}
      </TableCell>
      <TableCell>
        <Tooltip title={objectDescription || ''} enterDelay={300} leaveDelay={300} placement="top">
          <div
            className="purchaseTable__objectDescription"
            data-testid="purchaseTable--objectDescription"
            onClick={handleClick}> {isEditMode ?
            <ClickAwayListener onClickAway={handleClickAway}>
              <TextareaAutosize
                data-testid="purchaseTable__objectDescription__textarea"
                id="headline"
                variant="outlined"
                type="text"
                value={objectDescription}
                minRows={3}
                maxLength={2048}
                onChange={(e) => {
                  setObjectDescription(e.target.value)
                }}
                style={{width: '100%', padding: '8px', fontSize: '15px', maxLength: 500}}
              />
            </ClickAwayListener>
            : <span
              data-testid="purchaseTable__objectDescription__span"> {descSnipper(objectDescription) || '-'}</span>
          }
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={purchase.priceResolutionStrategy || ''} enterDelay={300} leaveDelay={200} placement="top"
                 arrow>
          <div className="nowrap" data-testid="purchaseTable--price">
            <PriceCurrencyLiteral price={purchase.price.value} currency={purchase.price.currency}/>
          </div>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={purchase.onlineTimeCalculationStrategy || ''} enterDelay={300} leaveDelay={200}
                 placement="top" arrow>
          <div data-testid="purchaseTable--onlineTime">
            <span data-testid="purchaseTable--onlineTimeStart">{toDateString(purchase.onlineTimeStart) || '-'}</span>
            &nbsp;-&nbsp;
            <span data-testid="purchaseTable--onlineTimeEnd">{toDateString(purchase.onlineTimeEnd) || '-'}</span>
          </div>
        </Tooltip>
      </TableCell>
      <TableCell className={classes.usages}>
        <ul className="list" data-testid="initial-usages-list">
          {initialUsages.map((u, index) => (
            <li key={index}>
              <span data-testid="purchaseTable--usage__departmentName">{u.department.name || '-'}</span>
              <span> | </span>
              <span data-testid="purchaseTable--usage__placementTime">{toDateString(u.placementTime) || '-'}</span>
              <span> | </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={u.documentPath ? prefixWithTenantDomain(u.documentPath) : '-'}
                data-testid="purchaseTable--usage__documentPath"
              >
                Artikel
              </a>
            </li>
          ))}
        </ul>
        <Collapse in={expanded}>
          <ul className="list" data-testid="additional-usages-list">
            {additionalUsages.map((u, index) => (
              <li key={index + 10}>
                <span data-testid="purchaseTable--usage__departmentName">{u.department.name || '-'}</span>
                <span> | </span>
                <span data-testid="purchaseTable--usage__placementTime">{toDateString(u.placementTime) || '-'}</span>
                <span> | </span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={u.documentPath ? prefixWithTenantDomain(u.documentPath) : '-'}
                  data-testid="purchaseTable--usage__documentPath"
                >
                  Artikel
                </a>
              </li>
            ))}
          </ul>
        </Collapse>
        {usages.length > 10 && (
          <div
            className="purchaseTable__toggle"
            onClick={() => setExpanded(!expanded)}
            style={{cursor: 'pointer', display: 'flex', alignItems: 'center', marginTop: '5px'}}
          >
            {expanded ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
            <span style={{marginLeft: '5px', color: '#1976d2', fontWeight: 'bold'}}>
            {expanded ? 'weniger anzeigen' : 'mehr anzeigen'}
          </span>
          </div>
        )}
      </TableCell>
    </TableRow>
  );
});

export default PurchaseRow;
