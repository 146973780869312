import './PurchaseTable.scss';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';



import PurchaseRow from './PurchaseRow';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: 600,
  },
  title: {
    flex: '1 1 100%',
  },
  table: {
    overflowY: 'hidden'
  },
  usages: {
    minWidth: 300,
  }
}));

// Table Head Preparation
const headCells = [
  { id: 'enrichment', numeric: false, disablePadding: false, label: '' },
  { id: 'assetImage', numeric: false, disablePadding: false, label: 'Asset' },
  { id: 'assetDamId', numeric: false, disablePadding: false, label: 'DAM-Id' },
  { id: 'credit', numeric: false, disablePadding: false, label: 'FotoCredit' },
  { id: 'assetForeignId', numeric: true, disablePadding: false, label: 'Fremd‐Id' },
  { id: 'department', numeric: false, disablePadding: true, label: 'Ressort' },
  { id: 'vendor', numeric: false, disablePadding: true, label: 'Anbieter' },
  { id: 'caption', numeric: false, disablePadding: true, label: 'Beschreibung' },
  { id: 'price', numeric: false, disablePadding: false, label: 'Honorar' },
  { id: 'onlineTime', numeric: false, disablePadding: false, label: 'Standzeit' },
  { id: 'Usages', numeric: false, disablePadding: false, label: 'Verwendungen' }
];

const PurchaseTable = ({ purchases, pendingCreditAdviceJobs, handleAssetDetails, handleSelectPurchase, isSelected, shouldShowCheckboxes, pendingMetadataJobs, isLoading }) => {
  const classes = useStyles();



  return (
      <TableContainer component={Paper} classes={{ root: classes.table }}>
        <Table className='purchaseTable' aria-label='simple table'>
          <TableHead className='table__head'>
            <TableRow>
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.id}
                  align='left'
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  classes={{ root: classes.root }}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              purchases && purchases.length > 0 ?
                purchases.map((purchase, idx) => {
                  const shouldDisableCheckbox = shouldShowCheckboxes && ( pendingMetadataJobs.includes(purchase.assetId.damAssetId) ||  pendingCreditAdviceJobs.includes(purchase.assetId.damAssetId)|| !!purchase.creditAdviceId.serialNumber )
                  return (
                    <PurchaseRow
                      key={idx}
                      purchase={purchase}
                      index={idx}
                      handleAssetDetails={handleAssetDetails}
                      handleSelectPurchase={handleSelectPurchase}
                      isSelected={isSelected}
                      shouldShowCheckbox={shouldShowCheckboxes && !isLoading}
                      shouldDisableCheckbox={shouldDisableCheckbox}
                    />
                  )
                }) :
                (
                  <TableRow key='1'>
                    <TableCell style={{ textAlign: 'center' }} colSpan={headCells.length} component='th' scope='row'>
                      Keine Verwendungen gefunden
                    </TableCell>
                  </TableRow>
                )
            }
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default PurchaseTable;
